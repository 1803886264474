.ListHeader {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .ListHeader {
    flex-direction: column;
    align-items: flex-start;
  }

  .ListHeader .actions {
    display: flex;
    justify-content: space-between;
  }
}
