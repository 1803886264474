.ChatMessageConfigurationReport {
  display: flex;
  flex-direction: column;
  font-style: italic;
}

.ChatMessageConfigurationReport > div {
  display: flex;
  align-items: center;
  gap: 2px;
}

.ChatMessageConfigurationReport svg {
  fill-opacity: 0.5;
  height: 20px;
}

.ChatMessageConfigurationControl {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  z-index: 11;
  display: flex;
  flex-direction: column;
}

.ChatMessageConfigurationControl.show {
  opacity: 1;
  transform: translateY(-20%);
}

.ChatMessageConfigurationControl.hide {
  opacity: 0;
  transform: translateY(100%);
}

.ChatMessageConfigurationControl .container {
  background: white;
  border-radius: 10px;
  box-shadow: 0px 12px 52px rgba(54, 56, 68, 0.16) !important;
  position: relative;
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.ChatMessageConfigurationControl .content {
  flex-grow: 1;
  overflow-y: auto;
  max-height: 200px;
}

.ChatMessageConfigurationControl .closeButton {
  position: absolute;
  right: 10px;
  top: 10px;
}

.ChatMessageConfigurationControl .header {
  display: flex;
  justify-content: space-between;
}

.ChatMessageConfigurationReport .warningMessage {
  color: red;
}
