.IncidentReportHeader {
  margin-bottom: 15px;
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.headerContent {
  margin: 15px 0 0 0;
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
}

.headerContent h1 {
  font-weight: 400;
  font-size: 28px;
  padding: 0;
  margin: 0;
}

.IncidentReportHeader button {
  background: rgba(220, 220, 220, 0.87);
}
