.KioskFeedbackImage {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

.KioskFeedbackImage label {
  padding-top: 10px;
  color: #333;
  font-size: 14px;
}

.KioskFeedbackImage {
  position: relative;
}

.imageContainer {
  position: relative;
  width: 100%;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eee;
  overflow: hidden;
}

.imageContainer img {
  transition: transform 0.3s ease-in-out;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.rotationButtons {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
  stroke: #fff;
}

.rotationButtons button {
  background-color: #01c0f7;
  border: none;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
  transition: opacity 0.1s ease-in-out;
  z-index: 999;
}

.rotationButtons button:hover {
  opacity: 0.9;
}

.skeleton {
  background: #eee;
  width: 100%;
  height: 450px;
}

.rotatableImage {
  width: 100%;
  height: 100%;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.lastUpdate {
  position: absolute;
  bottom: 10px;
  left: 10px;
  border-radius: 2px;
  padding: 2px 5px;
  background-color: rgba(233, 233, 233, 0.899);
}

.errorMessage {
  position: absolute;
  top: 0;
  left: 0;
  padding: 2px 5px;
  background-color: rgba(250, 22, 22, 0.788);
  color: #fff;
  width: 100%;
}
