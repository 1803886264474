.MessageFailureOptions {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.MessageFailureOptions .optionsRow {
  display: flex;
}

.MessageFailureOptions .errorMessage {
  color: red;
}
