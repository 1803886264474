.EntityManagementScreen .tabPanel {
  margin: 0.5rem 0 1rem 0;
  padding: 20px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.EntityManagementScreen .appBar {
  background: transparent;
  color: black;
  margin-top: 16px;
}
