@import url(https://fonts.googleapis.com/css?family=Open+Sans);
body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #E5E5E5;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    background-color: #E5E5E5
}

#root {
    font-family: 'Open Sans', sans-serif;
}

.ButtonRounded_ButtonRounded__IYTYl {
  font-size: 18px;
  padding: 8px 16px 8px 16px;
  border-radius: 300px;
  cursor: pointer;
  color: #fff;
  width: auto;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.ButtonRounded_ButtonRounded__IYTYl:disabled {
  cursor: not-allowed;
}

.ButtonRounded_ButtonRounded__IYTYl:hover {
  opacity: 0.9;
}

.react-datepicker__input-container {
	position: relative;
	/* display: inline-block; */
	width: 100%;
	text-align: center;
	font-size: x-large;
	font-weight: bold;
	/* pure/white */
	height: 45px !important;

    background: #FFFFFF;
    /* grey/grey-10 */

    border: 1px solid #E6EBF5;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
}

.react-datepicker-wrapper {
	text-align: center;
	width: 100%;
}

.Dropdown-control {
	font-size: 16px;
	margin-top: 15px;
	margin-bottom: 5px;
	padding-bottom: 2px !important;
	padding-top: 1px !important;
}

.Dropdown-option.is-selected {
	background-color: #009688 !important;
	color: white !important;
}
.Dropdown-option:hover {
	background-color: #009688 !important;
	color: white !important;
}

.filter-item {
	margin-bottom: 1%;
}

.Dropdown-menu {
	font-size: 16px !important;
}

.react-bootstrap-table-pagination-total {
	/* display:inline-block !important; */
	float: left !important;
	font-size: 15px !important;
	margin-top: 15px;
}
.pagination,
.react-bootstrap-table-page-btns-ul {
	/* display:inline-block !important; */
	float: right !important;
	margin-top: 15px;
}

.select-page-count {
	/* display:inline-block !important; */
	align-items: center;
	margin-top: 0px;
}
.orders-per-page-select {
	margin: auto !important;
}

.orders-per-page {
	margin-top: 15px;
	font-size: 15px;
}

.arrow-up {
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;

	border-bottom: 5px solid black;
	/* margin-top: 0px; */
	margin-bottom: 20px;
}

.arrow-down {
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;

	border-top: 5px solid #000;
}

@media only screen and (min-width: 1000px) {
    td {
        padding-bottom: 9px;
        padding-top: 9px;
    }

}

.up-down-caret {
	margin-right: 20px !important;
}

tbody tr:hover {
	/* background-color: #009688 !important; */
	background-color: #bdbdbd !important;
	/* background-color: #4bbebe !important; */
	color: white;
	/* transition: transform .2s;
    transform: scale(1.03);  */
}
tbody tr:hover td {
	border-radius: 0px;
}

.btn-large.pour-button:hover {
	background-color: #58cbe1 !important;
}

.pour-button, .done-button {
	width: 140px;
	/* height: 28px; */
	font-family: OpenSans;
	font-size: 18px;
	font-weight: 900;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 1.5px;
	text-align: center;
	color: #ffffff;
	margin-top: 0.5rem;
	
	user-select: none;
    -moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
    -o-user-select: none;
}

/* tr:nth-child(even) {
    background-color: #f2f2f2;
  } */
.sort-caret {
	font-size: 11px;
	color: #808b96;
}

tbody tr.no-bottom-border:hover {
	background-color: white !important;
	color: black !important;
}

.react-bootstrap-table table {
	table-layout: auto !important;
}

.styles_header__3PFGX {
	background-color: #00C4E9 !important;
	/* margin-bottom: 50px !important;
    padding-bottom: 50px !important; */
}

.container {
	/* margin-top: 115px !important; */
	width: 90%;
	max-width: 90% !important;
	/* margin-top: 5%; */
}

.Dropdown-control,
.Dropdown-menu {
}

.kiosk-dropdown {
	width: 55% !important;
	/* align-items: center; */
	margin: auto;
	text-align: center;
}


.timezone-dropdown {
	text-align: center;
}

.Dropdown-placeholder {
	text-align: center;
}

/* .progress {
    background-color: darkblue;
}


.progress .indeterminate {
    background-color: steelblue;
} */

iframe {
	/* width: 95%; */
	/* height: fit-content; */
	/* transform: scale(0.5); */
	/* height: 50%; */
	margin-left: 60%;
}

.replayer-wrapper {
	float: none;
	clear: both;
	-webkit-transform-origin: top left;
	        transform-origin: top left;
	left: 0%;
	top: 0%;
	-webkit-transform: scale(0.3);
	        transform: scale(0.3);
	/* margin: auto; */
	margin-left: 30%;
	margin-top: 30%;
	margin-bottom: 2%;
}

@media only screen and (min-width: 1000px) {
	/* .modal {
		top: 15% !important;
		z-index: 10 !important;
		max-height: 80%;
		height: 80%;
		width: 80%;
	} */
}

/* .modal-body {
	height: 90%;
} */

.rr-player {
	margin-left: 10%;
}

.rr-player__frame {
	min-height: 50% !important;
	max-height: 95%;
}

.confirm-restart {
	width: -webkit-fit-content !important;
	width: -moz-fit-content !important;
	width: fit-content !important;
	margin-top: -50% !important;
}

.confirm-restart-header {
	margin-bottom: 5% !important;
	padding-bottom: 5% !important;
	border-bottom: 0.5px solid #EAECEE;
}

.confirm-restart-body {
	margin-bottom: 5% !important;
	/* border-bottom: 0.5px solid #EAECEE; */
}

@media only screen and (max-width: 1500px) {
	/* 13 inch mac */
	.rr-player {
		margin-left: 4%;
	}
	.rr-player__frame {
		min-height: 50% !important;
		max-height: 95%;
		/* height: 90% ! */
	}
	/* modal {
		top: 9% !important;
		z-index: 10 !important;
		max-height: 100%;
		height: 95% !important;
		width: 80%;
	} */
}

@media only screen and (max-width: 992px) {
	/* modal {
		top: 9% !important;
		z-index: 10 !important;
		max-height: 100%;
		height: 95% !important;
		width: 80%;
	} */
}

select {
	display: block !important;
}

.welcome {
	margin-top: 10%;
	box-shadow: none;
}

.pour-button, .done-button {
	background-color: #ffffff;
	color: #626262;
	font-family: "Open Sans";
	letter-spacing: 1.5px;
	font-weight: 900;
	margin-right: auto;
	margin-left: auto;
}
.pour-button:active {
	box-shadow: 0 3px #666;
	-webkit-transform: translateY(2px);
	        transform: translateY(2px);
	background-color: #E0E4E8 !important;
	color: #fff;
}

.done-button:active, .done-button:hover, .pour-button:hover {
	background-color: #58cbe1 !important;
	color: #fff;
}

.drop-progress {

}

.dash-progress-spinner {
	margin-top: 10%;
}


.drop-progress-spinner, .dash-progress-spinner {
	margin-right: auto;
	margin-left: auto;
}

.summary-card .card-title {
	font-size: 1.5rem;
	font-weight: bold;
	letter-spacing: .0625rem;
	color: #636876;
	text-transform: capitalize !important;
	margin-top: 15px;
}

.summary-card.card-body.card-text {
	color: #6CE3FF  !important;
	font-weight: bold;
}
.summary-card.card-body.card-title {
	color: #636876  !important;
}

.filter-card {
	z-index: 1;
}

.filter-card.card-body.card-text, 
.filter-card.card-body.card-title {
    color: #000 !important;
    margin-top: 10px !important;
}

.summary-card-one {
	/* background: linear-gradient(75deg, #4638c2 0%, #2d2587 100%) !important; */
	/* background: linear-gradient(to bottom, rgba(30,87,153,1) 0%,rgba(41,137,216,1) 50%,rgba(32,124,202,1) 51%,rgba(125,185,232,1) 100%) !important; */
	/*background: #1914c6;*/
	/*background: -webkit-linear-gradient(left, #1914c6, #85609b);
	background: -moz-linear-gradient(left, #1914c6, #85609b);
	background: linear-gradient(to right, #1914c6, #85609b); */
	background: #FFFFFF;
}

.summary-card-two {
	background: #ffffff;
	/*background: -webkit-linear-gradient(right, #c68a14, #85609b);
	background: -moz-linear-gradient(right, #c68a14, #85609b);
	background: linear-gradient(to left, #c68a14, #85609b); */
}

.summary-card-three {
	/* background: linear-gradient(45deg, #ffc721 0%, #edb100 100%) !important; */
	background: #FFFFFF;
	/*background: -webkit-linear-gradient(left, #c68a14, #f6a346);
	background: -moz-linear-gradient(left, #c68a14, #f6a346);
	background: linear-gradient(to right, #c68a14, #f6a346); */
}

.summary-card-four {
    background: #FFFFFF;
	/* background: linear-gradient(45deg, #31b3e0 0%, #1d97c2 100%) !important; */
	/*background: #d47272;
	background: -webkit-linear-gradient(right, #d47272, #f6a346);
	background: -moz-linear-gradient(right, #d47272, #f6a346);
	background: linear-gradient(to left, #d47272, #f6a346); */
}

.summary-card-five {
    background: #FFFFFF;
	/* background: linear-gradient(45deg, #f98483 0%, #f75453 100%) !important; */


	/*background: #5a1313;
	background: -webkit-linear-gradient(right, #5a1313, #d05f5f);
	background: -moz-linear-gradient(right, #5a1313, #d05f5f);
	background: linear-gradient(to left, #5a1313, #d05f5f); */
}

.form-header {
    font-size: 14px !important;
    color: #000;
}


.menu-item {
	margin-top: 0px;
	height: 17px;
	font-family: "Open Sans";
	font-size: 12px;
	font-weight: 900;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 0.8px;
	text-align: center;
	color: #575757;
	text-transform: uppercase;

}

.bottle-icon {
	height: 160px;
	margin-top: 10%;
}

.screenshot-image {
	cursor: zoom-in;
}

.screenshot-modal {
	cursor: zoom-out;
}


.menu-item-2 {
	height: 17px;
	font-family: "Open Sans";
	font-size: 12px;
	font-weight: 900;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 0.8px;
	text-align: center;
	color: #575757;
	text-transform: uppercase;
}

.menu-item-price  {
	margin-top: -15px;
	height: 17px;
	font-family: "Open Sans";
	font-size: 12px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 0.8px;
	text-align: center;
	color: #575757;
}

.menu-location {
	font-family: "Open Sans";
	font-size: 14px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.57;
	letter-spacing: 0.6px;
	color: #aaaaaa;
	margin-top: 10%;
	height: 22px;
	text-align: center;
	margin-bottom: 10%;
}

.menu-select {
	height: 16px;
	font-family: "Open Sans";
	font-size: 16px;
	font-weight: 800;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 0.8px;
	color: #575757;
}

.menu-subselect {
	height: 44px;
	font-family: "Open Sans";
	font-size: 14px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.57;
	letter-spacing: 0.6px;
	color: #aaaaaa;
	/* margin-bottom: 10%; */
}


.pop-up-select {
	height: 16px;
	font-family: "Open Sans";
	font-size: 18px;
	font-weight: 900;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 1.5px;
	color: #575757;
}


.pop-up-subselect, .pop-up-subselect2 {
	height: 16px;
	font-family: "Open Sans";
	font-size: 14px;
	font-weight: 400;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 1.5px;
	color: #575757;
	text-align: center;
	width: 90%;
	margin-right: 10%;
	margin-left: 10%;
}

.pop-up-subselect2 {
	/* margin-top: -10%; */
	margin-bottom: 10%;
}

.edit-order-btn {
	/* margin-left: -15% !important;
	width: 130% !important; */
	box-shadow: none;
	background-color: #F2F3F4 !important;
}

.done-btn {
	/* margin-left: -25% !important;
	width: 150% !important; */
	/* font-size: 15px;
	font-weight: 900;
	letter-spacing: 0.8px;
	text-align: center;
	color: #C0C0C0;
	background: #ffffff;
	cursor: pointer; */
}

.order-again-btn {
	/* height: 22px; */
  /* font-family: "Open Sans"; */
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  text-align: center;
  color: #ffffff;
  object-fit: contain;
  border-radius: 30px;
  background-color: #6ce3ff;
  margin-top: 5%;
  cursor: pointer;
}

.done-btn {
	margin-top: 5%;
	font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.5px;
  text-align: center;
  color: #CACFD2;
  cursor: auto;
}

.receipt {
	/* margin-left: -25% !important;
    width: 150% !important; */
}

.edit-order-btn {
	font-size: 15px;
	font-weight: 900;
	letter-spacing: 0.8px;
	text-align: center;
	color: #6ce3ff;
	background: #ffffff;
}

.edit-order-btn-2 {
	/* width: 130%; */
	font-size: 15px;
	font-weight: 900;
	letter-spacing: 0.8px;
	text-align: center;
	color: #6ce3ff;
	box-shadow: none;
	background: #E5E7E9;
}

.cancel-order-btn {
	/* width: 130%; */
	font-size: 15px;
	font-weight: 900;
	letter-spacing: 0.8px;
	text-align: center;
	color: #ea7663;
	background: #ffffff;
	box-shadow: none;
	background-color: #F4F6F6;
}

.edit-order-btn:hover, .cancel-order-btn:hover, .edit-order-btn-2:hover  {
	cursor: pointer;
}

.right-receipt-item .td {

}

.kiosk-link {
	color: blue;
    text-decoration: underline;


}

.edit-order-btn:active {
	background-color: #e7e7e7;
}

.indicator.online {
    background: #28B62C;
}

.indicator {
    display: inline-block;
	width: 1em;
	margin-bottom: -3px;
    height: 1em;
    border-radius: 50%;
	margin: 0 3px;
}

.online-status {
	font-size: 20px;
}
/* @-webkit-keyframes pulse-animation {
	0% { -webkit-transform: scale(1); }
	25% { -webkit-transform: scale(1); }
    50% { -webkit-transform: scale(1.2) }
    75% { -webkit-transform: scale(1); }
    100% { -webkit-transform: scale(1); }
} */

.indicator.offline {
    background: #FF4136;
}


.wrap {
	white-space: pre-wrap;
	width: 100%;
	overflow-wrap: normal;
}

.selected {
	box-shadow: 0 0 20px rgba(42, 215, 254, 0.8); 
}

.separator {
	margin-bottom: -5%;
	/* height: 0.5%; */
}

.wave-header {
	height: 25%;
	top: 0%;
	z-index: -1 !important;
}

.wave-header-img {
	height: 45%;
}

hr {
	height: 0.3px;
	border: solid 1px #e7e7e7;
	margin-top: 10%;
	background-color: #e7e7e7;
	margin-right: 5%;
	margin-left: 5%;
	margin-bottom: 5%;
}

.out-of-stock {
	-webkit-user-select: none !important;
	        user-select: none !important;
	pointer-events: none !important;
	box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 1.5px 0.5px -1px rgba(0,0,0,0.12), 0 0.5px 2.5px 0 rgba(0,0,0,0.2);
	background-color: #F2F3F4 !important;
	opacity: 0.7;
}

.drop-link {
	margin-top: 2.0rem;
	text-align: right;
	color: #566573;
}

.order-price {
	margin-top: 5%;
	margin-bottom: 5%;
	font-family: "Open Sans";
	font-size: 15px;
	font-weight: 900;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 1.5px;
	color: #575757;
}

.confirm-btn {
	font-size: 15px;
	font-weight: 900;
	letter-spacing: 0.8px;
	text-align: center;
	color: #ffffff;
	box-shadow: none;
}

.confirm-btn:hover {
	cursor: pointer;
}

.receipt-header {

}

.refill-card, .drop-progress {
	webkit-box-shadow: none !important;
    box-shadow:none !important;
}

.right-receipt-item {
	text-align: right;
	width: 55%;
	border-bottom: none;
	font-family: "Open Sans";
	font-size: 12px;
	font-weight: 800;
	letter-spacing: 0.8px;
	color: #626262;
	text-transform:capitalize;
}

.left-receipt-item {
	text-transform:capitalize;
	text-align: right;
	font-family: "Open Sans";
	font-size: 12px;
	font-weight: 300;
	letter-spacing: 0.8px;
	color: #626262;
	width: 100%;
}

tr.no-bottom-border {
	border-bottom: none;
}

tr.no-bottom-border:hover {
	background-color: #ffffff;
}

.inventory-summary-header {
	border-right: 0.5px solid #EAECEE;
}

.kiosk {
	color: black !important;
}


@media only screen and (max-width: 800px) {
	.wave-header {
		height: auto;
	}
	.wave-header-img {
		height: auto;
	}

	.bottle-icon {
		height: 100px;
		margin-top: 10%;
	}

	.container {
		width: 97%;
		max-width: 97% !important;
	}
	.pie-chart {
		width: 100% !important;
	}

	.dash-progress-spinner {
		margin-top: 20%;
	}
	nav {
		line-height: 56px !important;
		height: 30px;
	}

	nav a {
		border-radius: 0px !important;
		color: #fff !important;
	}

	nav a:hover {
		color: #fff;
		background-color: #1ABC9C !important;
	}

	nav a.active {
		background-color: #00acc1;
	}

	ul.collapsed.isexpanded {
		width: 40% !important;
		max-height: 800px !important;
		height: 800px !important;
		margin-right: 0px !important;

		position: fixed !important;
		justify-content: flex-start !important;
		border-radius: 0px !important;
	}

	header {
		z-index: 1000 !important;
	}

	table {
		overflow: visible;
		display: block;
		table-layout: auto;
		}

	.table-wrapper {
		overflow-x: scroll !important;
	}

	.logo {
		height: 30px;
	}

	.logo-image {
		overflow-x: hidden !important;
		width: 50%;
		margin-left: -8% !important;
		margin-top: 4%;
	}

	.summary-card {
		width: 100% !important;
		margin: 0;
		margin-bottom: 10px;
    }

    .row {
        overflow-x: scroll;
    }
    /*
    .summary-card-one {
		background: #1914c6;
        background: -webkit-linear-gradient(to top, #1914c6, #85609b);
        background: -moz-linear-gradient(to top, #1914c6, #85609b);
        background: linear-gradient(to bottom, #1914c6, #85609b);
    }
    
    .summary-card-two {
        background: #c68a14;
        background: -webkit-linear-gradient(to bottom #c68a14, #85609b);
        background: -moz-linear-gradient(to bottom, #c68a14, #85609b);
        background: linear-gradient(to top, #c68a14, #85609b);
    }
    
    .summary-card-three {
        background: #c68a14;
        background: -webkit-linear-gradient(to top, #c68a14, #f6a346);
        background: -moz-linear-gradient(to top, #c68a14, #f6a346);
        background: linear-gradient(to bottom, #c68a14, #f6a346);
    }
    
    .summary-card-four {
        background: #d47272;
        background: -webkit-linear-gradient(to bottom#d47272, #f6a346);
        background: -moz-linear-gradient(to bottom, #d47272, #f6a346);
        background: linear-gradient(to top, #d47272, #f6a346);
    }
    
    .summary-card-five {
        background: #5a1313;
        background: -webkit-linear-gradient(to bottom, #5a1313, #d05f5f);
        background: -moz-linear-gradient(to bottom#5a1313, #d05f5f);
        background: linear-gradient(to top, #5a1313, #d05f5f);
	}*/
	
	.filter-div {
		margin-bottom: 5%;
		margin-top: 5%;
	}

	.filter-item, .date-dropdown {
		margin-bottom: 4%;
	}
	
}

#tags-standard, #tags-standard1, #tags-standard2, #tags-standard3, #tags-standard4, #tags-standard5 {
	/* border-bottom: none !important; */
	margin-bottom: -5px;
	border-bottom: transparent;
}

#tags-standard5 {
	text-align: center;
}

.MuiLinkButton {
	background-color: transparent !important;
}

.MuiAccordionDetails-root {
	display: block !important;
	/* margin-bottom: 15%; */
}

.MuiAutocomplete-popper { 
	text-align: center !important;
}

.MuiAutocomplete-option {
	/* text-align: center; */
	display: block !important;
}

.clear-filters:hover, .clear-filters:click {
	background-color: transparent !important;
}

.clear-filters {
	background-color: transparent !important;
}

.chosen-tags {
	background-color: #01C0F7;
	color: white;
}

.date-dropdown {
	text-align: center;
	border-bottom: none !important;
	color: #636876 !important;
}

.carousel-item {
	margin-top: -50px !important;
	/* transform: translateX(134px) translateY(50px) translateX(0px) translateX(0px) translateZ(0px) !important; */
}

.screenshot-modal .MuiDialog-container .MuiPaper-root {
	background-color: transparent !important;
	box-shadow: none;
}

.screenshot-modal-header {
	background-color: #01C0F7;
	border-radius: 30px;
	color: white;
}

.carousel .indicators .indicator-item.active {
	background-color: #01AB89;
}

.carousel .indicators .indicator-item {
	background-color: #88F726;
}

.MuiPaper-root.MuiDrawer-paper {
	background-color: white !important;
	opacity: 1.0;
}

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
			  user-select: none; /* Non-prefixed version, currently
									supported by Chrome, Edge, Opera and Firefox */
  }


  .dot {
	height: 20px;
	width: 20px;
	background-color: #bbb;
	border-radius: 50%;
	/* padding-top: 10px; */
	margin-bottom: -7px;
	display: inline-block;
  }


.Calendar {
    font-family: "Open Sans";
}

.Calendar .header {
    font-weight: 900;
    font-size: 16px;
}

.calendar-dates span {
    width: 40px !important;
    border-radius: 50%;
}

.day-header span {
    width: 40px !important;
    font-weight: 900;
}

.overview-header {
    padding: 0px !important;
}

@media only screen and (min-width: 750px) {

    .DatePicker {
        margin-right: 3%;
    }

}

@media only screen and (max-width: 600px) {
	.confirm-restart {
		margin-top: -150% !important;
	}


    .DatePicker {
        width: 96%;
    }

    .overview-header-orders .DatePicker {
        width: 40%;
    }

     .second-picker {
        margin-top: 10px !important;
     }

     .overview-header-orders .react-datepicker__input-container {
        /* display: inline-block; */
        width: 50%;
        float: left;

     }


}

@media only screen and (min-width: 400px) and (max-width: 1200px){
    .filter-drawer .MuiPaper-root {
        /*width: 50% !important; */
    }


}


@media only screen and (min-width: 550) and (max-width: 550){

}

.filter-icon-header {
    position: fixed;
    width: 100%;
    z-index: 999999 !important;
    background-color: #FFFFFF !important;
    cursor: pointer;
}

.filter-icon-header:hover {
  background-color: #e6e3e3 !important;
}

.filter-items-list {
    margin-left: 6% !important;

}

.filter-items-header {
    margin-left: 9% !important;
}

.filter-button {
    background: #FFFFFF  !important;
    background-color: #FFFFFF  !important;
    border-radius: 8px  !important;
    border: 1px solid #E6EBF5 !important;
    transition: box-shadow .25s !important;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    /* web/Body-Extra-Small */

    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #636876;
    padding-right: 20px !important;
    padding-left: 20px !important;
}

.filter-drawer .MuiPaper-root.MuiDrawer-paper {
    opacity: 1 !important;

}

.reset-filters {
    background-color: #E6EBF5  !important;
    border-radius: 8px  !important;
}

.filter-reset-button {


    background-color: #6CE3FF  !important;
    border-radius: 8px  !important;
}

@media only screen and (min-width: 992px) {
    .reset-filters {
        margin-left: 20px  !important;

    }

    .filter-reset-button {
        width: 86px  !important;
        margin-left: 245px !important;
        margin-right: 15px !important;
    }
}

@media only screen and (min-width: 380px) and (max-width: 992px) {
    .reset-filters {
        margin-left: 10px  !important;

    }

    .filter-reset-button {
        width: 56px  !important;
        margin-left: 245px !important;
        margin-right: 15px !important;
    }

}

@media only screen and (max-width: 380px) {
    .reset-filters {
        margin-left: 10px  !important;
    }

    .filter-reset-button {
        margin-left: 230px !important;
        margin-right: 15px !important;
    }

}

/* computer */
@media only screen and (min-width: 1024px) {
    .reset-filters {
        margin-left: 10px  !important;

    }

    .filter-reset-button {
        width: 56px  !important;
        right: 0px;
    }

    .MuiAutocomplete-popper {
        width: 14.8% !important;
        margin-top: 3%;
    }

}


/* Pixel 2, iPhone 6 */
@media only screen and (min-width: 370px) and (max-width: 568px) {
    .reset-filters {
        margin-left: 10px  !important;

    }

    .filter-reset-button {
        width: 56px  !important;
        margin-left: 240px !important;
        right: 0px;
        margin-right: 15px !important;
    }

    .MuiAutocomplete-popper {
        width: 40% !important;
        margin-top: 8%;
    }


}


/* Small phones Moto G4, Galaxy S5*/
@media only screen and (min-width: 320px) and (max-width: 370px) {
    .reset-filters {
        margin-left: 10px  !important;

    }

    .filter-reset-button {
        width: 56px  !important;
        margin-left: 220px !important;
        right: 0px;
        margin-right: 15px !important;
    }

    .MuiAutocomplete-popper {
        width: 40% !important;
        margin-top: 8%;
    }


}

@media only screen and (min-width: 290px) and (max-width: 320px) {
    .reset-filters {
        margin-left: 10px  !important;

    }

    .filter-reset-button {
        width: 56px  !important;
        margin-left: 180px !important;
        right: 0px;
        margin-right: 15px !important;
    }

}

@media only screen and (max-width: 290px) {
    .reset-filters {
        margin-left: 10px  !important;

    }

    .filter-reset-button {
        width: 56px  !important;
        margin-left: 140px !important;
        right: 0px;
        margin-right: 15px !important;
    }

}


.timezone-input-box {
    width: 184px;
    height: 40px;
    background: #FFFFFF !important;
    /* web/standard */

    box-shadow: 0px 4px 20px rgba(54, 56, 68, 0.08);
    border-radius: 8px;
}


.download-csv-button {
    background: #6CE3FF;
    border-radius: 8px;
    height: 40px;
    font-weight: 800;
    font-size: 14px;
    font-family: 'Open Sans';
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    border: none;
    cursor: pointer;
    padding-right: 20px;
    padding-left: 20px;
}





.top_logo {
  margin-top: 10px;
}

#user-account input {
  padding: 0 5px;
}

.DataTable_DataTable__OtD0i input[type='checkbox'] {
  opacity: 1;
  position: inherit;
  width: 16px;
  height: 16px;
  accent-color: rgb(21, 72, 98, 1);
}

.DataTable_DataTable__OtD0i td {
  border-radius: 0px;
}

.PageHeading_PageHeading__3MqO6 {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.PageHeading_PageHeading__3MqO6 button {
  background: rgba(220, 220, 220, 0.4);
}

@media (max-width: 768px) {
  .PageHeading_PageHeading__3MqO6 button {
    display: none;
  }
}

.EntityListActions_EntityListActions__3Ktek {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
}

.EntityListActions_EntityListActions__3Ktek select {
  padding: 8px 16px;
  border-radius: 300px;
  font-size: 18px;
  padding: 8px 16px 8px 16px;
  border-radius: 300px;
  border: 1px solid rgb(23, 71, 97);
  color: rgb(23, 71, 97);
  cursor: pointer;
  width: auto;
}

.EntityListActions_EntityListActions__3Ktek select:hover {
  opacity: 0.9;
}

.ListHeader_ListHeader__1GpJV {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .ListHeader_ListHeader__1GpJV {
    flex-direction: column;
    align-items: flex-start;
  }

  .ListHeader_ListHeader__1GpJV .ListHeader_actions__mVM3a {
    display: flex;
    justify-content: space-between;
  }
}

.EntityCreate_EntityCreate__1VICZ {
  padding: 0px 8px 16px 8px;
}

.CheckboxList_list__3iz3q {
  display: block;
  padding: 0;
}

.CheckboxList_row__hMedU {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 16px;
  gap: 16px;
  padding: 8px 0px!important;
}

.CheckboxList_listItem__3qS3L {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto !important;
  padding: 0px!important;
  cursor: pointer;
}

p {
  margin-bottom: 0px;
}

.EntityForm_EntityForm__1yd_0 .EntityForm_submit__1zDBH {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background-color: white;
  padding: 12px 0px;
  border-top: 1px solid #ccc;
}

.EntityForm_EntityForm__1yd_0 {
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-gap: 8px;
  gap: 8px;
}

.EntityForm_EntityForm__1yd_0 .EntityForm_fieldGroup__3c2FX {
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
  border-bottom: 8px #f9f9f9 solid;
}
.SectionHeading_SectionHeading__cR1Dx {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.SectionHeading_SectionHeading__cR1Dx h2 {
  font-weight: 400;
  font-size: 20px;
  margin: 15px 0;
}

.SectionHeading_SectionHeading__cR1Dx h3 {
  font-weight: 400;
  font-size: 18px;
  margin: 15px 0;
}

.KioskPumpEditCard_KioskPumpEditCard__2pMKe {
  display: grid;
  grid-gap: 10px;
  gap: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 12px 52px rgba(54, 56, 68, 0.16) !important;
  background: #fff;
  place-items: center;
  margin-top: 10px;
}


.KioskPumpEditCard_KioskPumpEditCard__2pMKe .KioskPumpEditCard_pumpAmountInput__2GclJ {
  padding: 5px 15px;
  border: 0;
}

.KioskPumpEditCard_KioskPumpEditCard__2pMKe .KioskPumpEditCard_pumpAmountInput__2GclJ:focus {
  border: 2px solid #3f51b5;
}

.KioskPumpEditCard_KioskPumpEditCard__2pMKe .KioskPumpEditCard_tableIcon__2RhMQ {
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: 100px 100px;
}

.KioskPumpEditCard_KioskPumpEditCard__2pMKe .KioskPumpEditCard_alignCenter__3x3gV {
  text-align: center;
  border: 'none';
}

.KioskTowerEditCard_KioskTowerEditCard__TgUJR {
  display: grid;
  grid-gap: 10px;
  gap: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 12px 52px rgba(54, 56, 68, 0.16) !important;
  background: #fff;
  place-items: center;
  margin-top: 10px;
}

.KioskTowerEditCard_KioskTowerEditCard__TgUJR .KioskTowerEditCard_activeTowerSelect__2alGz {
  text-align: center;
}

.KioskTowerEditCard_KioskTowerEditCard__TgUJR .KioskTowerEditCard_towerAmountInput__15JRs {
  padding: 5px 15px;
  border: 0;
}

.KioskTowerEditCard_KioskTowerEditCard__TgUJR .KioskTowerEditCard_towerAmountInput__15JRs:focus {
  border: 2px solid #3f51b5;
}

.KioskTowerEditCard_KioskTowerEditCard__TgUJR .KioskTowerEditCard_alignCenter__5BcI5 {
  text-align: center;
  border: 'none';
}

.PeripheralCommunication_PeripheralCommunication__3jrKd {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 20px 0;
  font-size: 18px;
  -webkit-user-select: text;
          user-select: text;
  padding-bottom:50vh
}

.PeripheralCommunication_PeripheralCommunication__3jrKd button {
  cursor: pointer;
  padding: 6px;
  border-radius: 5px;
  background: #ccc;
  color: #333;
  text-transform: uppercase;
  font-weight: 700;
}

.PeripheralCommunication_PeripheralCommunication__3jrKd button:disabled {
  color: #999;
  background: #ccc;
}

.PeripheralCommunication_PeripheralCommunication__3jrKd .PeripheralCommunication_errorMessage__2qapL {
  border: 1px solid #eee;
  background: rgba(255, 0, 0, 0.157);
  padding: 20px;
  margin: 20px;
  color: red;
  font-weight: bold;
}

.PeripheralCommunication_PeripheralCommunication__3jrKd .PeripheralCommunication_headingContainer__2oiVk {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.PeripheralCommunication_PeripheralCommunication__3jrKd .PeripheralCommunication_headingContainer__2oiVk h2 {
  margin: 0;
}

.PeripheralCommunication_PeripheralCommunication__3jrKd table {
  margin: 20px 0;
}
.PeripheralCommandInputs_PeripheralCommandInputs__xcSQN input[type=checkbox] {
    background: #000!important;
    opacity: 1;
    position: relative;
    pointer-events: all;
    margin: 10px
}

.PeripheralCommandInputs_PeripheralCommandInputs__xcSQN label {
    color: #333;
    font-size: large;
    scale: (5)
}
.GantryControls_GantryJogInput__2N77s {
  display: flexbox;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  flex-direction: row;
  align-items: center;
}

.GantryControls_grid3__Of0A0 {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  align-items: center;
  justify-content: center;
}

.GantryControls_grid3__Of0A0 > * {
  display: flex;
  align-items: center;
  justify-content: center;
}

.GantryControls_GantryJogInput__2N77s .GantryControls_iconButton__1VX2U {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 20;
  gap: 20;
}

.GantryControls_GantryJogInput__2N77s button:focus {
  border: none;
  border-radius: 2pt;
  box-shadow: 0 0 0 1pt grey;
  outline-color: transparent;
  transition: 0.1s;
}

.GantryControls_controlButtons__1EPC3 {
  display: 'flex';
  flex-direction: 'column';
  align-items: 'center';
}

.GantryControls_toggleInputType__dHF_A {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.GantryControls_toggleInputType__dHF_A label {
  color: #333;
}

.KioskFeedbackImage_KioskFeedbackImage__1UOK_ {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 20px;
}

.KioskFeedbackImage_KioskFeedbackImage__1UOK_ label {
  padding-top: 10px;
  color: #333;
  font-size: 14px;
}

.KioskFeedbackImage_KioskFeedbackImage__1UOK_ {
  position: relative;
}

.KioskFeedbackImage_imageContainer__3bb_Q {
  position: relative;
  width: 100%;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eee;
  overflow: hidden;
}

.KioskFeedbackImage_imageContainer__3bb_Q img {
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.KioskFeedbackImage_rotationButtons__2QHa5 {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  stroke: #fff;
}

.KioskFeedbackImage_rotationButtons__2QHa5 button {
  background-color: #01c0f7;
  border: none;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
  transition: opacity 0.1s ease-in-out;
  z-index: 999;
}

.KioskFeedbackImage_rotationButtons__2QHa5 button:hover {
  opacity: 0.9;
}

.KioskFeedbackImage_skeleton__6BC5v {
  background: #eee;
  width: 100%;
  height: 450px;
}

.KioskFeedbackImage_rotatableImage__3vztm {
  width: 100%;
  height: 100%;
}

.KioskFeedbackImage_loadingContainer__3ixbr {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.KioskFeedbackImage_lastUpdate__1tom1 {
  position: absolute;
  bottom: 10px;
  left: 10px;
  border-radius: 2px;
  padding: 2px 5px;
  background-color: rgba(233, 233, 233, 0.899);
}

.KioskFeedbackImage_errorMessage__Sutzy {
  position: absolute;
  top: 0;
  left: 0;
  padding: 2px 5px;
  background-color: rgba(250, 22, 22, 0.788);
  color: #fff;
  width: 100%;
}

.GantryPositionDisplay_table__3fY9U {
  width: 100%;
  border-collapse: collapse;
}

.GantryPositionDisplay_header__3KYGA {
  border-bottom: 1px solid #ddd;
  padding: 8px;
}

.GantryPositionDisplay_cell__3Lj4U {
  border-bottom: 1px solid #ddd;
  padding: 8px;
}

.KioskEdit_KioskEdit__1jfwD {}

.KioskEdit_KioskEdit__1jfwD .KioskEdit_headingContainer__1OU67 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Card_CardContainer__1AzGv {
  margin: 0.5rem 0 1rem 0;
  background-color: #fff;
  transition: box-shadow 0.25s;
  border-radius: 2px;
  padding: 30px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.IncidentReportHeader_IncidentReportHeader__3P5_0 {
  margin-bottom: 15px;
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
}

.IncidentReportHeader_headerContent__12o3k {
  margin: 15px 0 0 0;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  flex: 1 1;
}

.IncidentReportHeader_headerContent__12o3k h1 {
  font-weight: 400;
  font-size: 28px;
  padding: 0;
  margin: 0;
}

.IncidentReportHeader_IncidentReportHeader__3P5_0 button {
  background: rgba(220, 220, 220, 0.87);
}

.MessageCard_messageCard__1C_Wk {
  margin-bottom: 10px;
  padding: 10px;
  width: auto;
  background: #f9f9f9;
  border-radius: 10px;
  -webkit-animation: MessageCard_slideIn__zMW__ 0.4s ease-in-out;
          animation: MessageCard_slideIn__zMW__ 0.4s ease-in-out;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.MessageCard_messageCard__1C_Wk .MessageCard_senderName__1qxIy {
  font-weight: bold;
}

.MessageCard_messageCard__1C_Wk .MessageCard_timestamp__35cnb {
  font-size: 0.8em;
  color: #888;
}

.MessageCard_messageCard__1C_Wk .MessageCard_messageContent__4lHCW {
  margin: 10px 0;
  font-size: 14px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}

.MessageCard_messageCard__1C_Wk.MessageCard_kiosk-software__3vLbj {
  background: #f9f9f9;
}

.MessageCard_messageCard__1C_Wk .MessageCard_senderDetails__3v-Xb {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.MessageCard_messageCard__1C_Wk .MessageCard_senderDetails__3v-Xb .MessageCard_senderWithTimestamp__1RuqB {
  display: flex;
  flex-direction: column;
}

.MessageCard_messageCard__1C_Wk .MessageCard_recipients__39iAH {
  display: flex;
  padding-bottom: 10px;
}

@-webkit-keyframes MessageCard_slideIn__zMW__ {
  from {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

@keyframes MessageCard_slideIn__zMW__ {
  from {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
.SendReportDisplay_SendReportDisplay__37AVa {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  font-size: 12px;
  font-style: italic;
}

.SendReportDisplay_SendReportDisplay__37AVa svg {
    width: 20px;
    height: 20px;
}
.MessageList_MessageList__3mGcE {
  padding: 10px;
  overflow-y: auto;
  flex: 1 1;
  grid-gap: 5px;
  gap: 5px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start; 
}

.ChatMessageInput_ChatMessageInput__3KYb4 {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
  position: relative;
}

.ChatMessageInput_ChatMessageInput__3KYb4 textarea {
  padding: 10px;
  border: solid #ccc 1px;
  border-radius: 5px;
  min-height: 100px;
  resize: none;
}

.ChatMessageInput_ChatMessageInput__3KYb4 .ChatMessageInput_submitRow__2IpSo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ChatMessageInput_ChatMessageInput__3KYb4 .ChatMessageInput_submitButton__32x0V {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}

.ChatMessageInput_ChatMessageInput__3KYb4 .ChatMessageInput_configureMessageRow__1h2hN {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
}

.ChatMessageInput_ChatMessageInput__3KYb4 .ChatMessageInput_configureMessageRow__1h2hN button {
  padding: 2px;
}

.ChatMessageInput_ChatMessageInput__3KYb4 .ChatMessageInput_configureMessageRow__1h2hN button:focus {
  background: transparent;
}

.ChatMessageConfiguration_ChatMessageConfigurationReport__2d6U8 {
  display: flex;
  flex-direction: column;
  font-style: italic;
}

.ChatMessageConfiguration_ChatMessageConfigurationReport__2d6U8 > div {
  display: flex;
  align-items: center;
  grid-gap: 2px;
  gap: 2px;
}

.ChatMessageConfiguration_ChatMessageConfigurationReport__2d6U8 svg {
  fill-opacity: 0.5;
  height: 20px;
}

.ChatMessageConfiguration_ChatMessageConfigurationControl__2qeNK {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  z-index: 11;
  display: flex;
  flex-direction: column;
}

.ChatMessageConfiguration_ChatMessageConfigurationControl__2qeNK.ChatMessageConfiguration_show__cdApI {
  opacity: 1;
  -webkit-transform: translateY(-20%);
          transform: translateY(-20%);
}

.ChatMessageConfiguration_ChatMessageConfigurationControl__2qeNK.ChatMessageConfiguration_hide__2a6Gm {
  opacity: 0;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}

.ChatMessageConfiguration_ChatMessageConfigurationControl__2qeNK .ChatMessageConfiguration_container__3SBf0 {
  background: white;
  border-radius: 10px;
  box-shadow: 0px 12px 52px rgba(54, 56, 68, 0.16) !important;
  position: relative;
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.ChatMessageConfiguration_ChatMessageConfigurationControl__2qeNK .ChatMessageConfiguration_content__2WHpZ {
  flex-grow: 1;
  overflow-y: auto;
  max-height: 200px;
}

.ChatMessageConfiguration_ChatMessageConfigurationControl__2qeNK .ChatMessageConfiguration_closeButton__3cCB- {
  position: absolute;
  right: 10px;
  top: 10px;
}

.ChatMessageConfiguration_ChatMessageConfigurationControl__2qeNK .ChatMessageConfiguration_header__1DWUr {
  display: flex;
  justify-content: space-between;
}

.ChatMessageConfiguration_ChatMessageConfigurationReport__2d6U8 .ChatMessageConfiguration_warningMessage__1tITs {
  color: red;
}

.ChatWidget_messageDrawer__1r1bx {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  justify-content: space-between;
  overflow-y: auto;
}

.ChatWidget_drawerContent__2R19w {
  flex: 1 1;
  overflow-y: auto;
}

.ChatWidget_messageDialog__IPyUa {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow-y: hidden;
}

.ChatWidget_dialogTitle__1AjKz {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  border-bottom: 1px solid #ddd;
}

.ChatWidget_dialogTitle__1AjKz h3 {
  margin: 0;
  padding: 0;
  font-size: 24px;
}

.ChatWidget_dialogTitle__1AjKz p {
  margin: 0;
  padding: 10px 0;
}

.ChatWidget_drawerTitle__1O4tx {
  padding: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  border-bottom: 1px solid #ddd;
}

.ChatWidget_drawerTitle__1O4tx h3 {
  font-weight: 400;
  font-size: 24px;
}

.ChatWidget_dialogContent__3RTPj {
  flex: 1 1;
  overflow-y: auto;
}

.ChatWidget_stickyInput__1grNI {
  
  width: 100%;
  bottom: 0;
  background-color: white;
  padding: 16px;
  border-top: 1px solid #ddd;
}

.ChatWidget_dialogTitle__1AjKz .ChatWidget_description__137PT {
  margin-top: 8px;
  color: #666;
  padding: 10px;
}

.IconButtonWithLabel_IconButtonWithLabel__1ksVT {
  border: none;
  background: none;
  width: auto;
  align-items: center;
  height: auto;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  cursor: pointer;
}

.IconButtonWithLabel_IconButtonWithLabel__1ksVT:focus {
  background: none;
}

.IconButtonWithLabel_IconButtonWithLabel__1ksVT:hover {
  text-decoration: underline;
}

.MessageFailureOptions_MessageFailureOptions__QC2xM {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.MessageFailureOptions_MessageFailureOptions__QC2xM .MessageFailureOptions_optionsRow__2Y1GQ {
  display: flex;
}

.MessageFailureOptions_MessageFailureOptions__QC2xM .MessageFailureOptions_errorMessage__SKg1k {
  color: red;
}

.EntityEditScreen_EntityManagementScreen__TsFi_ .EntityEditScreen_tabPanel__2IZji {
  margin: 0.5rem 0 1rem 0;
  padding: 20px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.EntityEditScreen_EntityManagementScreen__TsFi_ .EntityEditScreen_appBar__3neDX {
  background: transparent;
  color: black;
  margin-top: 16px;
}

.KioskAlertPreferenceReport_container__1sMX- {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 5px 10px;
}

.KioskAlertPreferenceReport_container__1sMX-:hover {
  background: rgb(0, 0, 0, 0.1);
}

.KioskAlertPreferenceReport_container__1sMX-:hover svg {
  opacity: 1;
}

.KioskAlertPreferenceReport_container__1sMX- svg {
  opacity: 0.5;
}

.KioskAlertPreferenceReport_kioskDetails__18hGO {
  font-weight: bold;
}

.KioskAlertPreferenceReport_location__3vHtI {
  margin-top: 2px;
  font-size: 0.9rem;
  color: #666;
}

.KioskAlertPreferenceReport_notificationInfo__3mWDJ {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.KioskAlertPreferenceReport_notificationIcons__1sEaO {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.KioskAlertPreferenceReport_warning__2iWPg {
  background: rgba(251, 241, 53, 0.1);
}

.KioskAlertPreferenceReport_critical__1wlwr {
  background: rgb(251, 122, 53, 0.1);
}

.KioskAlertPreferenceReport_statusMessage__2G4jn p {
  margin: 0px;
}

.KioskAlertPreferenceReport_statusMessage__2G4jn {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

@media (max-width: 768px) {
  .KioskAlertPreferenceReport_container__1sMX- {
    grid-template-columns: 1fr;
  }

  .KioskAlertPreferenceReport_statusMessage__2G4jn {
    grid-column: 1;
    grid-gap: 2px;
    gap: 2px;
  }

  .KioskAlertPreferenceReport_notificationInfo__3mWDJ {
    margin: 8px 0px;
    grid-gap: 8px;
    gap: 8px;
  }
}
p.UserKioskAlertsPreferences_description__wNHzD {
  margin: 0px 0px 10px 0px;
}

