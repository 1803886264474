.messageCard {
  margin-bottom: 10px;
  padding: 10px;
  width: auto;
  background: #f9f9f9;
  border-radius: 10px;
  animation: slideIn 0.4s ease-in-out;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.messageCard .senderName {
  font-weight: bold;
}

.messageCard .timestamp {
  font-size: 0.8em;
  color: #888;
}

.messageCard .messageContent {
  margin: 10px 0;
  font-size: 14px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}

.messageCard.kiosk-software {
  background: #f9f9f9;
}

.messageCard .senderDetails {
  display: flex;
  gap: 10px;
}

.messageCard .senderDetails .senderWithTimestamp {
  display: flex;
  flex-direction: column;
}

.messageCard .recipients {
  display: flex;
  padding-bottom: 10px;
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}