.KioskTowerEditCard {
  display: grid;
  gap: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 12px 52px rgba(54, 56, 68, 0.16) !important;
  background: #fff;
  place-items: center;
  margin-top: 10px;
}

.KioskTowerEditCard .activeTowerSelect {
  text-align: center;
}

.KioskTowerEditCard .towerAmountInput {
  padding: 5px 15px;
  border: 0;
}

.KioskTowerEditCard .towerAmountInput:focus {
  border: 2px solid #3f51b5;
}

.KioskTowerEditCard .alignCenter {
  text-align: center;
  border: 'none';
}
