.PageHeading {
  display: flex;
  align-items: center;
  gap: 20px;
}

.PageHeading button {
  background: rgba(220, 220, 220, 0.4);
}

@media (max-width: 768px) {
  .PageHeading button {
    display: none;
  }
}
