.EntityForm .submit {
  position: sticky;
  bottom: 0;
  background-color: white;
  padding: 12px 0px;
  border-top: 1px solid #ccc;
}

.EntityForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
}

.EntityForm .fieldGroup {
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
  border-bottom: 8px #f9f9f9 solid;
}