.PeripheralCommunication {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 20px 0;
  font-size: 18px;
  user-select: text;
  padding-bottom:50vh
}

.PeripheralCommunication button {
  cursor: pointer;
  padding: 6px;
  border-radius: 5px;
  background: #ccc;
  color: #333;
  text-transform: uppercase;
  font-weight: 700;
}

.PeripheralCommunication button:disabled {
  color: #999;
  background: #ccc;
}

.PeripheralCommunication .errorMessage {
  border: 1px solid #eee;
  background: rgba(255, 0, 0, 0.157);
  padding: 20px;
  margin: 20px;
  color: red;
  font-weight: bold;
}

.PeripheralCommunication .headingContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.PeripheralCommunication .headingContainer h2 {
  margin: 0;
}

.PeripheralCommunication table {
  margin: 20px 0;
}