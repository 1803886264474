.ChatMessageInput {
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
}

.ChatMessageInput textarea {
  padding: 10px;
  border: solid #ccc 1px;
  border-radius: 5px;
  min-height: 100px;
  resize: none;
}

.ChatMessageInput .submitRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ChatMessageInput .submitButton {
  display: flex;
  gap: 10px;
  align-items: center;
}

.ChatMessageInput .configureMessageRow {
  display: flex;
  gap: 5px;
}

.ChatMessageInput .configureMessageRow button {
  padding: 2px;
}

.ChatMessageInput .configureMessageRow button:focus {
  background: transparent;
}
