.SendReportDisplay {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  font-style: italic;
}

.SendReportDisplay svg {
    width: 20px;
    height: 20px;
}