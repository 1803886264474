.SectionHeading {
  display: flex;
  align-items: center;
  gap: 20px;
}

.SectionHeading h2 {
  font-weight: 400;
  font-size: 20px;
  margin: 15px 0;
}

.SectionHeading h3 {
  font-weight: 400;
  font-size: 18px;
  margin: 15px 0;
}
