.ButtonRounded {
  font-size: 18px;
  padding: 8px 16px 8px 16px;
  border-radius: 300px;
  cursor: pointer;
  color: #fff;
  width: auto;
  display: flex;
  align-items: center;
  gap: 8px;
}

.ButtonRounded:disabled {
  cursor: not-allowed;
}

.ButtonRounded:hover {
  opacity: 0.9;
}
