.KioskPumpEditCard {
  display: grid;
  gap: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 12px 52px rgba(54, 56, 68, 0.16) !important;
  background: #fff;
  place-items: center;
  margin-top: 10px;
}


.KioskPumpEditCard .pumpAmountInput {
  padding: 5px 15px;
  border: 0;
}

.KioskPumpEditCard .pumpAmountInput:focus {
  border: 2px solid #3f51b5;
}

.KioskPumpEditCard .tableIcon {
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: 100px 100px;
}

.KioskPumpEditCard .alignCenter {
  text-align: center;
  border: 'none';
}
