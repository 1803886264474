.DataTable input[type='checkbox'] {
  opacity: 1;
  position: inherit;
  width: 16px;
  height: 16px;
  accent-color: rgb(21, 72, 98, 1);
}

.DataTable td {
  border-radius: 0px;
}
