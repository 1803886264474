.PeripheralCommandInputs input[type=checkbox] {
    background: #000!important;
    opacity: 1;
    position: relative;
    pointer-events: all;
    margin: 10px
}

.PeripheralCommandInputs label {
    color: #333;
    font-size: large;
    scale: (5)
}