.list {
  display: block;
  padding: 0;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  padding: 8px 0px!important;
}

.listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto !important;
  padding: 0px!important;
  cursor: pointer;
}

p {
  margin-bottom: 0px;
}
