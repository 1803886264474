.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 5px 10px;
}

.container:hover {
  background: rgb(0, 0, 0, 0.1);
}

.container:hover svg {
  opacity: 1;
}

.container svg {
  opacity: 0.5;
}

.kioskDetails {
  font-weight: bold;
}

.location {
  margin-top: 2px;
  font-size: 0.9rem;
  color: #666;
}

.notificationInfo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.notificationIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.warning {
  background: rgba(251, 241, 53, 0.1);
}

.critical {
  background: rgb(251, 122, 53, 0.1);
}

.statusMessage p {
  margin: 0px;
}

.statusMessage {
  display: flex;
  align-items: center;
  gap: 8px;
}

@media (max-width: 768px) {
  .container {
    grid-template-columns: 1fr;
  }

  .statusMessage {
    grid-column: 1;
    gap: 2px;
  }

  .notificationInfo {
    margin: 8px 0px;
    gap: 8px;
  }
}