.IconButtonWithLabel {
  border: none;
  background: none;
  width: auto;
  align-items: center;
  height: auto;
  display: flex;
  gap: 5px;
  cursor: pointer;
}

.IconButtonWithLabel:focus {
  background: none;
}

.IconButtonWithLabel:hover {
  text-decoration: underline;
}
