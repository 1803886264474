.table {
  width: 100%;
  border-collapse: collapse;
}

.header {
  border-bottom: 1px solid #ddd;
  padding: 8px;
}

.cell {
  border-bottom: 1px solid #ddd;
  padding: 8px;
}
