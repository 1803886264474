.GantryJogInput {
  display: flexbox;
  gap: 10px;
  width: 100%;
  flex-direction: row;
  align-items: center;
}

.grid3 {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  align-items: center;
  justify-content: center;
}

.grid3 > * {
  display: flex;
  align-items: center;
  justify-content: center;
}

.GantryJogInput .iconButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20;
}

.GantryJogInput button:focus {
  border: none;
  border-radius: 2pt;
  box-shadow: 0 0 0 1pt grey;
  outline-color: transparent;
  transition: 0.1s;
}

.controlButtons {
  display: 'flex';
  flex-direction: 'column';
  align-items: 'center';
}

.toggleInputType {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.toggleInputType label {
  color: #333;
}
