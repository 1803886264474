.EntityListActions {
  display: flex;
  gap: 16px;
}

.EntityListActions select {
  padding: 8px 16px;
  border-radius: 300px;
  font-size: 18px;
  padding: 8px 16px 8px 16px;
  border-radius: 300px;
  border: 1px solid rgb(23, 71, 97);
  color: rgb(23, 71, 97);
  cursor: pointer;
  width: auto;
}

.EntityListActions select:hover {
  opacity: 0.9;
}
