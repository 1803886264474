.messageDrawer {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  justify-content: space-between;
  overflow-y: auto;
}

.drawerContent {
  flex: 1;
  overflow-y: auto;
}

.messageDialog {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow-y: hidden;
}

.dialogTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  border-bottom: 1px solid #ddd;
}

.dialogTitle h3 {
  margin: 0;
  padding: 0;
  font-size: 24px;
}

.dialogTitle p {
  margin: 0;
  padding: 10px 0;
}

.drawerTitle {
  padding: 10px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  border-bottom: 1px solid #ddd;
}

.drawerTitle h3 {
  font-weight: 400;
  font-size: 24px;
}

.dialogContent {
  flex: 1;
  overflow-y: auto;
}

.stickyInput {
  
  width: 100%;
  bottom: 0;
  background-color: white;
  padding: 16px;
  border-top: 1px solid #ddd;
}

.dialogTitle .description {
  margin-top: 8px;
  color: #666;
  padding: 10px;
}
